"use strict";

var html = document.documentElement;
var body = document.body;
/**
  * ハンバーガーメニュー
  */

var hamburger = document.querySelector('.js-hamburger');

function openHeaderNav() {
  hamburger.addEventListener('click', function () {
    var openFlg = hamburger.classList.contains('is-open');
    var gNav = document.querySelector('.js-hamburger__menu');

    if (openFlg) {
      // body.classList.remove('is-open');
      gNav.classList.remove('is-open');
      hamburger.classList.remove('is-open');
      html.style.overflow = '';
    } else {
      // body.classList.add('is-open');		
      gNav.classList.add('is-open');
      hamburger.classList.add('is-open');
      html.style.overflow = 'hidden';
    }
  });
}

if (hamburger) {
  openHeaderNav();
}
/**
  * スムーススクロール実行関数
  */


var smoothScrollTrigger = document.querySelectorAll('a[href^="#"]');

var _loop = function _loop(i) {
  smoothScrollTrigger[i].addEventListener('click', function (e) {
    e.preventDefault();
    var href = smoothScrollTrigger[i].getAttribute('href');
    var targetElement = document.getElementById(href.replace('#', ''));
    var rect = targetElement.getBoundingClientRect().top;
    var offset = window.pageYOffset; // const gap = smoothOffset ? smoothOffset : 0;

    var target = rect + offset;
    window.scrollTo({
      top: target,
      behavior: 'smooth'
    });
  });
};

for (var i = 0; i < smoothScrollTrigger.length; i++) {
  _loop(i);
}